<template>
	<div class="qy-wechat">

		<div class="qy-wechat-wraper">
			<el-card class="left-card">
				<div class="title">开启微信客服</div>
				<div class="content">
					<div class="step-tip">
						（1）进入企业微信后台，依次点击应用管理→应用，在基础应用版块，点击「微信客服」，并点击「开始使用」
					</div>
					<img class="detail-img" :src="list.IsQyWeixinExternalContactInternalMall?img1:img4" />
					<div class="step-tip">
						<span v-if="list.IsQyWeixinExternalContactInternalMall">
						（2）点击「开启API」，并分别复制下方URL、Token、EncodingAESKey，并粘贴填入至企业微信后台对应的输入框中
						</span>
						<span v-else>
							（2）开启API，授权第三方管理微信客服
						</span>
					</div>
					<div v-if="list.IsQyWeixinExternalContactInternalMall">
						<div class="set-info">
							<div class="left">
								<div class="tit">URL：</div>
								<div class="txt">{{list.QyWeixinKfConfigUrl}}</div>
							</div>
							<div class="copy">
								<el-button type="primary" size="mini" v-clipboard:copy="list.QyWeixinKfConfigUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
							</div>
						</div>
						<div class="set-info">
							<div class="left">
								<div class="tit">Token：</div>
								<div class="txt">{{list.Token}}</div>
							</div>
							<div class="copy">
								<el-button type="primary" size="mini" v-clipboard:copy="list.Token" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
							</div>
						</div>
						<div class="set-info">
							<div class="left">
								<div class="tit">EncodingAESKey：</div>
								<div class="txt">{{list.EncodingAESKey}}</div>
							</div>
							<div class="copy">
								<el-button type="primary" size="mini" v-clipboard:copy="list.EncodingAESKey" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
							</div>
						</div>
					</div>
					<img class="detail-img" :src="list.IsQyWeixinExternalContactInternalMall?img3:img7" />
					<div class="step-tip">
						<span v-if="list.IsQyWeixinExternalContactInternalMall">
						（3）API开启成功后，Secret配置区，点击「查看」，并将获取到的Secret复制粘贴到下方输入框中，点击「保存」即可
						</span>
						<span v-else>
							（3）返回应用管理主页，点击第三方应用-「企店助手」，同意授权微信客服相关权限
						</span>
						
					</div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" style="margin-top: 20px;"
					 v-if="list.IsQyWeixinExternalContactInternalMall">
						<el-form-item label="Secret" prop="Secret">
							<el-input v-model="ruleForm.Secret" style="max-width: 400px;" placeholder="微信客服Secret"></el-input>
						</el-form-item>
					</el-form>
					<img class="detail-img" :src="list.IsQyWeixinExternalContactInternalMall?img2:img6" />
				</div>

			</el-card>

			<el-card class="right-card">
				<div class="right-tips">
					<div class="tips">
						<div>【提示】因企业微信API限制，目前<span style="color: #e7a43c;">配置流程较为复杂</span>，</div>
						<div>若自行配置可能<span style="color: #e7a43c;">会配置失败</span>，</div>
						<div>请联系专属客服，我们会帮你配置</div>
					</div>
					<img class="rwm" src="@/assets/img/20210719171848.jpg" />
					<div class="rwm-tip">
						<div>微信扫码，添加专属客服</div>
						<div style="color: #e7a43c;">一对一帮你完成配置</div>
					</div>
				</div>
			</el-card>
		</div>
		
		<div class="footer">
			<el-button type="primary" @click="handleFinished">{{list.IsQyWeixinExternalContactInternalMall?'保存':'我已完成配置，开始使用微信客服'}}
			</el-button>
		</div>
		
	</div>


	
</template>

<script>
	import {
		qyWeixinKfSecretSave,
		qyWeixinKfInit,
		qyweixinsuiteauthinfo
	} from "@/api/sv3.0.0.js";
	export default {
		data() {
			return {
				ruleForm: {
					Secret: ''
				},
				rules: {
					Secret: [{
						required: true,
						message: '请输入微信客服Secret',
						trigger: 'blur'
					}]
				},
				list: {},
				wxscrect: "",
				img1:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/serviceaccount1.png',
				img3:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/serviceaccount3.png',
				img2:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/serviceaccount2.png',

				img4:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/serviceaccount4.png',
				img7:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/serviceaccount7.png',
				img6:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/serviceaccount6.png',
			};
		},
		created() {
			this.getqyWeixinKfInit()
			this.qyweixinsuiteauthinfo()
		},
		methods: {
			//复制成功
			onCopy(e) {
				this.$message.success(`复制成功,请前往企业微信后台粘贴在对应输入框内`);
			},
			//复制失败
			onError(e) {
				this.$message.error(`复制失败`);
			},
			//保存配置
			handleFinished() {
				// console.log(this.$refs['ruleForm'])
				if(this.list.IsQyWeixinExternalContactInternalMall){
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							this.qyWeixinKfSecretSave();
						} else {
							//
						}
					});
				}else{
					this.qyWeixinKfSecretSave();
				}
			},
			async qyWeixinKfSecretSave() {
				try {
					let data = {
						//Secret (string, optional): 客服秘钥
						Secret: this.ruleForm.Secret,
					};
					let result_ = await qyWeixinKfSecretSave(data);
					console.log(result_);
					
					this.$router.push({
						path: "/customerServe/WxCustomerServe/serviceaccount", //跳转客服账号列表页
					});
				} finally {}
			},
			//获取微信SCRECT
			async qyweixinsuiteauthinfo() {
				try {
					let result = await qyweixinsuiteauthinfo()
					this.wxscrect = result.Result.SelfBuiltSecret
				} finally {}
			},
			//获取客服密钥
			async getqyWeixinKfInit() {
				try {
					let result = await qyWeixinKfInit()
					this.list = result.Result
					console.log(this.list.IsQyWeixinExternalContactInternalMall,'IsQyWeixinExternalContactInternalMall')
				} finally {}
			},
		}
	}
</script>

<style lang="less" scoped>
	.qy-wechat {


		.qy-wechat-wraper {
			display: flex;
		}

		.left-card {
			flex: 1 1 auto;
		}

		.right-card {
			flex: 0 0 auto;
			width: 380px;
			height: 400px;
			margin-left: 20px;

			.tips {
				line-height: 22px;
				font-size: 13px;
				text-align: center;
			}

			.rwm {
				display: block;
				width: 200px;
				margin: 20px auto 0;
			}

			.rwm-tip {
				font-size: 14px;
				color: #666;
				text-align: center;
				margin-top: 10px;
				line-height: 20px;
			}
		}
	}

	.title {
		font-size: 16px;
		font-weight: bold;
	}

	.content {
		margin: 30px 0;

		.content-title {
			height: 40px;
			line-height: 40px;
			background-color: #f5f5f5;
			font-size: 14px;
			color: #666;
			padding-left: 20px;
			margin-bottom: 30px;
		}

		.form-item-tip {
			line-height: 20px;
			color: #999;
			margin-top: 10px;
			font-size: 12px;
		}
		
		.step-tip{
			padding: 8px 10px;
			font-size: 14px;
			border: 1px solid #409EFF;
			line-height: 22px;
			min-height: 22px;
			background-color: #fbfdff;
			margin-top: 20px;
			margin-bottom: 20px;
			margin-right: 36px;
		}
		
		.detail-img{
			display: block;
			max-width: 80%;
		}
		
		.set-info {
			padding: 10px 20px;
			background-color: #F8F8F9;
			border: 1px solid #eee;
			margin-bottom: 20px;
		
			display: flex;
			overflow: hidden;
		
			.left {
				flex: 1 1 auto;
				overflow: hidden;
				color: #666;
		
				.tit {
					font-size: 15px;
					margin-bottom: 10px;
					color: #999999;
				}
		
				.txt {
					font-size: 14px;
					color: #333;
					word-break: break-all;
				}
			}
		
			.copy {
				flex: 0 0 auto;
				margin-left: 40px;
				margin-top: 10px;
			}
		}
	}

	.footer {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
		background-color: #fff;
	}
</style>
